import { useState } from 'react'
import ReactGA from 'react-ga4'
import { datadogRum } from '@datadog/browser-rum'
import { ApolloError } from '@apollo/client'
import type { IProductDetailProps } from '../interfaces/actions'
import useAppDispatch from './useAppDispatch'
import useAppSelector from './useAppSelector'
import { useLazyGetProductBySku } from '../graphQL/queries/getProductBySku'
import {
  clearProductDetail,
  setCurrentBundlePriceValue,
  setCurrentBundleSubtotal,
  setProductDetail,
} from '../redux/slices/catalog'
import { generateDataDogContext } from '../utils/dataDog'
import { IGetProductBySku } from '../graphQL/queries/getProductBySku/types'
import {
  getFlattedCartItems,
  setProductsQuantity,
} from '../utils/utilsForCatalogReducer'
import { deleteExcludedPromotionTypes } from '../utils/excludePromotions'
import { getAnalyticsItem } from '../utils/getAnalyticsItems'
import { PromotionType } from '../graphQL/commons'
import { castIProduct } from '../utils/castICatalogItems'
import getBundleTotal from '../utils/catalog/getBundleTotal'

const useProductDetail = () => {
  const [excludedPromotions, setExcludedPromotions] =
    useState<PromotionType[]>()

  const config = useAppSelector((state) => state.defaultSlice.config)
  const sessionId = useAppSelector((state) => state.defaultSlice.sessionId)
  const storeName = useAppSelector((state) => state.defaultSlice.storeName)
  const cartItems = useAppSelector((state) => state.cartSlice.items)
  const cartGroups = useAppSelector((state) => state.cartSlice.groups)
  const activePromotions = useAppSelector(
    (state) => state.cartSlice.activePromotions,
  )
  const subItem =
    useAppSelector((state) => state.catalogSlice.subItemEdit) || undefined

  const dispatch = useAppDispatch()

  const onCompleted = async (data: IGetProductBySku) => {
    const { flattedCartItems } = getFlattedCartItems({
      items: cartItems,
      groups: cartGroups,
    })

    const { promotions = [], ...product } = castIProduct(
      data.getProductBySKU,
      config,
    )

    const [result] = setProductsQuantity({
      products: [
        {
          ...product,
          promotions: excludedPromotions?.length
            ? deleteExcludedPromotionTypes(promotions, excludedPromotions)
            : promotions,
        },
      ],
      config,
      flattedCartItems,
      activePromotions,
      treatAsMultiPromo: true,
    })
    const productAnalyticItem = getAnalyticsItem(result)
    const analyticsPayload = {
      currency: config?.options?.currencyPrefix || '',
      value: result.price,
      items: [productAnalyticItem],
    }
    const { total, subtotal } = getBundleTotal({ product: result, subItem })
    await dispatch(setProductDetail({ product: result, subItem }))
    await dispatch(setCurrentBundlePriceValue(result.price || total))
    await dispatch(setCurrentBundleSubtotal(result.price || subtotal))
    ReactGA.event('view_item', analyticsPayload)
  }

  const onError = (error: ApolloError) => {
    const context = generateDataDogContext({
      title: 'could not fetch product detail',
      extraInfo: { function: 'useProductDetail' },
    })
    datadogRum.startView(context.viewName)
    datadogRum.addError(error, context)
  }

  const [getProductBySku] = useLazyGetProductBySku({ onError, onCompleted })

  const getProductDetail = async ({
    sku,
    promotionsTypesToExclude = [],
  }: IProductDetailProps) => {
    try {
      setExcludedPromotions(promotionsTypesToExclude)
      dispatch(clearProductDetail())

      await getProductBySku({
        variables: {
          sku,
          sessionId: sessionId || '',
          storefrontName: storeName || '',
        },
      })
    } catch (exception) {
      const context = generateDataDogContext({
        title: 'could not select product detail',
        extraInfo: { sku, function: 'productDetail' },
      })
      datadogRum.startView(context.viewName)
      datadogRum.addError(exception, context)
    }
  }

  return getProductDetail
}

export default useProductDetail
