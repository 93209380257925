import { IPromotion } from '../../graphQL/commons'
import { useAddItemToCart } from '../cart'
import { getPromotionFields } from '../../utils/common/getPromotionFields'
import { Referrers } from '../../consts/defaultConfigValues/defaultConstants'
import useAppSelector from '../useAppSelector'

interface IAddProductFromPromotionArgs {
  promotion: IPromotion
}
export const useAddProductFromPromotion = () => {
  const selectedProduct = useAppSelector(
    (state) => state.catalogSlice.selectedProduct,
  )
  const { addItemAction } = useAddItemToCart()

  const addProductFromPromotion = ({
    promotion,
  }: IAddProductFromPromotionArgs) => {
    const { customerBuysQuantity } = getPromotionFields(promotion)
    if (selectedProduct) {
      const { quantity: value } = selectedProduct
      const newValue = value + customerBuysQuantity
      addItemAction({
        product: selectedProduct,
        value,
        newValue,
        referrer: Referrers.PROMOTION_DETAIL,
      })
    }
  }
  return { addProductFromPromotion }
}

export default useAddProductFromPromotion
