import { ApolloError } from '@apollo/client'
import { datadogRum } from '@datadog/browser-rum'
import { Referrers } from '../../consts/defaultConfigValues/defaultConstants'
import usePrefillCartMutation from '../../graphQL/mutations/prefillCart'
import { IPrefillCartMutationResponse } from '../../graphQL/mutations/prefillCart/types'
import { ICartProduct, ICartProductInput } from '../../interfaces'
import { setSynchronizingCart } from '../../redux/slices/catalog'
import { onCartResponse } from '../../utils/onCartResponse'
import useAppDispatch from '../useAppDispatch'
import useAppSelector from '../useAppSelector'
import { generateDataDogContext } from '../../utils/dataDog'

interface AddPrefillCartArgs {
  items: ICartProduct[]
  storefrontName: string
  sessionUid: string
  onCompleted?: (data: IPrefillCartMutationResponse) => void
}

export const useAddPrefillCart = () => {
  const dispatch = useAppDispatch()
  const stateRedux = useAppSelector((state) => state)

  /**
   * Handles onComplete callback from prefill cart mutation
   */
  const onCompletePrefillCartMutation = (
    data: IPrefillCartMutationResponse,
  ) => {
    if (data?.prefillCart) {
      onCartResponse({
        data: data.prefillCart,
        state: stateRedux,
        dispatch,
      })
    }
    dispatch(setSynchronizingCart(false))
  }

  /**
   * Handles onError callback from prefill cart mutation
   */
  const onErrorPrefillCartMutation = (error: ApolloError) => {
    dispatch(setSynchronizingCart(false))
    const context = generateDataDogContext({
      title: 'Prefill cart mutation error',
      extraInfo: { function: 'usePrefillCartMutation' },
    })
    datadogRum.startView(context.viewName)
    datadogRum.addError(error, context)
  }

  /**
   * Get prefillCart mutation hook
   */
  const [sendPrefillCart] = usePrefillCartMutation({
    onCompleted: onCompletePrefillCartMutation,
    onError: onErrorPrefillCartMutation,
  })

  /**
   * Adds prefill cart items to cart
   */
  const addPrefillCart = async (args: AddPrefillCartArgs) => {
    const { items, storefrontName, sessionUid, onCompleted } = args
    dispatch(setSynchronizingCart(true))
    const itemsToAdd = items
      .filter((item) => item.quantity > 0)
      .map<ICartProductInput>((item) => ({
        sku: item.sku,
        quantity: item.quantity,
        selectedPackage: item.package?.name,
      }))

    const response = await sendPrefillCart({
      variables: {
        storefrontName,
        sessionUid,
        items: itemsToAdd,
        referrer: Referrers.PREFILL_CART,
      },
    })
    if (response?.data) {
      onCompleted?.(response.data)
    }
  }

  return { addPrefillCart }
}
